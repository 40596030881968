import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchDairySuppliedStore = defineStore('searchDairySuppliedStore', {
  state: () => ({
    Dairy_id: null as number | null,
    useTerms: true,
    items: [{ key: 'Dairy_ident', operation: ':', itemType: 'string', isList: true }] as TermItemDefinition[]
  })
})
